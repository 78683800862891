@import '../../../base/bootstrap-extended/include';
@import '../../../base/components/include';

@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

.css-1rhbuit-multiValue{
  background-color: #96ba3e;
}

.rdw-editor-wrapper {
  .rdw-editor-toolbar {
    margin-bottom: 0;
    padding: 0.5rem;
    padding-bottom: 0;
    border-color: $border-color;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    .rdw-option-wrapper {
      border: none;
      padding: 13px 5px;
      &.rdw-option-active {
        box-shadow: none;
        background-color: rgba($color: $primary, $alpha: 0.2);
      }
      &:hover {
        box-shadow: none;
      }
    }
    .rdw-dropdown-wrapper {
      border: 1px solid $border-color;
      .rdw-dropdown-optionwrapper {
        &:hover {
          box-shadow: none;
        }
      }
      &:hover {
        box-shadow: none;
        .rdw-dropdown-selectedtext {
          color: $primary;
        }
      }
      .rdw-dropdownoption-default {
        &.rdw-dropdownoption-highlighted {
          color: $primary;
          background: transparent;
        }
      }
    }
    .rdw-link-modal,
    .rdw-embedded-modal {
      height: auto;
    }
  }
  .rdw-editor-main {
    min-height: 10rem;
    padding: 0.5rem 1.2rem;
    border: 1px solid $border-color;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
  .rdw-editor-toolbar ~ .rdw-editor-main {
    border-top: none;
  }

  &.toolbar-bottom {
    display: flex;
    flex-direction: column;
    .rdw-editor-toolbar {
      order: 2;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
    .rdw-editor-main {
      border-top: 1px solid $border-color;
      border-bottom: 0;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      order: 1;
    }
  }
}

.dark-layout {
  .rdw-editor-wrapper {
    .rdw-editor-toolbar {
      background: $theme-dark-body-bg;
      border-color: $theme-dark-border-color;
      .rdw-option-wrapper {
        background: $theme-dark-body-bg;
        img {
          filter: invert(100%);
        }
        &.rdw-option-active {
          background-color: rgba($color: $primary, $alpha: 0.2) !important;
        }
      }
      .rdw-dropdown-wrapper {
        background: $theme-dark-body-bg;
        border-color: $theme-dark-border-color;
        .rdw-dropdown-carettoopen {
          border-top-color: $theme-dark-body-color;
        }
        .rdw-dropdown-optionwrapper {
          background: $theme-dark-body-bg;
          border-color: $theme-dark-border-color;
          .rdw-dropdownoption-active {
            background: $theme-dark-card-bg;
          }
        }
      }
    }
    .rdw-editor-main {
      border-color: $theme-dark-border-color;
    }
    .rdw-emoji-modal,
    .rdw-image-modal,
    .rdw-embedded-modal,
    .rdw-colorpicker-modal,
    .rdw-link-modal {
      background: $theme-dark-body-bg;
      border-color: $theme-dark-border-color;
      box-shadow: 3px 3px 5px $theme-dark-body-bg;

      input {
        color: $theme-dark-body-color;
        border: $theme-dark-border-color;
        background-color: $theme-dark-card-bg;
      }
    }
  }
}
